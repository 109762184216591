export default [
  {
    component: 'CNavItem',
    name: '首页',
    to: '/dashboard',
    icon: 'cil-home',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  {
    component: 'CNavGroup',
        name: '用户管理',
        to: '/pages',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: '用户列表',
        to: '/user/userList',
        icon: 'cil-circle',
      }
    ],   
  },
  {
    component: 'CNavGroup',
    name: 'BMS',
    to: '/pages',
    icon: 'cil-star',
    items: [
      // {
      //   component: 'CNavItem',
      //   name: '用户管理',
      //   to: '/bms',
      //   icon: 'cil-circle',
      //   items: [
      //     {
      //       component: 'CNavItem',
      //       name: '用户列表',
      //       to: '/bms/userList',
      //     }
      //   ]
      // },
      // {
        // component: 'CNavItem',
        // name: '设备管理',
        // to: '/bms',
        // icon: 'cil-circle',
        // items: [
          {
            component: 'CNavItem',
            name: '设备类型',
            to: '/bms/deviceType',
            icon: 'cil-circle',
          },
          {
            component: 'CNavItem',
            name: '设备列表',
            to: '/bms/deviceList',
            icon: 'cil-circle',
          },
          {
            component: 'CNavItem',
            name: '故障管理',
            to: '/bms/fault',
            icon: 'cil-circle',
          },
      //   ]
      // },
      // {
      //   component: 'CNavItem',
      //   name: '系统管理',
      //   to: '/bms',
      //   icon: 'cil-circle',
      //   items: [
      //     {
      //       component: 'CNavItem',
      //       name: '用户协议',
      //       to: '/bms/agreement',
      //     },
      //     {
      //       component: 'CNavItem',
      //       name: '隐私条款',
      //       to: '/bms/privacy',
      //     },
      //     {
      //       component: 'CNavItem',
      //       name: 'APP更新',
      //       to: '/bms/update',
      //     },
      //   ]
      // },
      
    ],
  },

  {
    component: 'CNavGroup',
    name: '控制器',
    to: '/pages',
    icon: 'cil-star',
    items: [
      // {
      //   component: 'CNavItem',
      //   name: '控制器列表',
      //   to: '/bms/controllerList',
      //   icon: 'cil-circle',
      // },
      {
        component: 'CNavItem',
        name: '控制器类型',
        to: '/controller/controllerType',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'CAN配置文件',
        to: '/controller/CAN',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: '图标',
        to: '/controller/icons',
        icon: 'cil-circle',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '系统管理',
    to: '/pages',
    icon: 'cil-star',
    items: [
      {
        component: 'CNavItem',
        name: '用户协议',
        to: '/system/agreement',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: '隐私条款',
        to: '/system/privacy',
        icon: 'cil-circle',
      },
      {
        component: 'CNavItem',
        name: 'APP更新',
        to: '/system/update',
        icon: 'cil-circle',
      },
    ]
  },

 
]
