<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar1" size="md" :key="key" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem
        @click="
          () => {
            modal = true
          }
        "
      >
        <CIcon icon="cil-Address-Book" /> 个人信息
      </CDropdownItem>
      <CDropdownItem
        @click="
          () => {
            show = true
          }
        "
      >
        <CIcon icon="cil-Camera" /> 修改头像
      </CDropdownItem>
      <CDropdownItem @click="back">
        <CIcon icon="cil-exit-to-app" /> 退出登录
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
  <CModal
    size="lg"
    :visible="modal"
    alignment="center"
    @close="
      () => {
        modal = false
      }
    "
  >
    <CModalHeader>
      <CModalTitle>个人信息</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm class="row g-3">
        <CCol :md="6">
          <CFormLabel>昵称</CFormLabel>
          <CFormInput v-model="inputPname" placeholder="请输入昵称" />
        </CCol>
        <CCol :md="6">
          <CFormLabel>真实姓名</CFormLabel>
          <CFormInput v-model="inputName" placeholder="请输入姓名" />
        </CCol>
        <CCol :md="6">
          <CFormLabel>手机</CFormLabel>
          <CFormInput v-model="inputPhone" placeholder="请输入手机号" />
        </CCol>
        <CCol :md="6">
          <CFormLabel>邮箱</CFormLabel>
          <CFormInput v-model="inputEmail" placeholder="请输入邮箱" />
        </CCol>
        <CCol :md="6">
          <CFormLabel>性别</CFormLabel>
          <CFormSelect v-model="sex" @change="change5">
            <option v-for="(item, index) in sexlist" v-bind:key="index">
              {{ item.text }}
            </option>
          </CFormSelect>
        </CCol>
        <CCol :md="6">
          <CFormLabel>公司</CFormLabel>
          <CFormInput v-model="inputCompany" placeholder="请输入公司名称" />
        </CCol>
        <CCol :md="3">
          <CFormLabel>居住地-省</CFormLabel>
          <CFormSelect v-model="provinceName" @change="change1">
            <option v-for="(item, index) in provSel" v-bind:key="index">
              {{ item }}
            </option>
          </CFormSelect>
        </CCol>
        <CCol :md="3">
          <CFormLabel>市</CFormLabel>
          <CFormSelect v-model="cityName" @change="change2">
            <option v-for="(item, index) in citySel" v-bind:key="index">
              {{ item }}
            </option>
          </CFormSelect>
        </CCol>
        <CCol :md="3">
          <CFormLabel>区</CFormLabel>
          <CFormSelect v-model="areaName" @change="change3">
            <option v-for="(item, index) in areaSel" v-bind:key="index">
              {{ item }}
            </option>
          </CFormSelect>
        </CCol>
        <CCol :md="3">
          <CFormLabel>街道</CFormLabel>
          <CFormSelect v-model="streetName">
            <option v-for="(item, index) in streetSel" v-bind:key="index">
              {{ item }}
            </option>
          </CFormSelect>
        </CCol>
        <CCol :xs="12">
          <CFormLabel>详细地址</CFormLabel>
          <CFormInput v-model="inputPlace" placeholder="请输入详细地址" />
        </CCol>
        <CCol :xs="12" style="text-align: center">
          <CButton color="primary" @click="verify">保存修改</CButton>
        </CCol>
      </CForm>
    </CModalBody>
  </CModal>
  <CModal
    size="lg"
    :visible="show"
    alignment="center"
    @close="
      () => {
        show = false
      }
    "
  >
    <CModalHeader>
      <CModalTitle>头像修改</CModalTitle>
    </CModalHeader>
    <CModalBody style="height: 45vh; width: 100vw">
      <CRow style="width:100vw">
        <CCol :xs="3" style="height: 30vh">
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :fixed="fixed"
            :fixed-number="fixedNumber"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            @real-time="realTime"
            :high="option.high"
            @img-load="imgLoad"
            mode="cover"
            :max-img-size="option.max"
            @crop-moving="cropMoving"
          ></vue-cropper>
        </CCol>
        <CCol :xs="2">
          <div
            class="show-preview"
            :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              overflow: 'hidden',
              margin: '2.5vw',
              borderRadius: '100%',
            }"
          >
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <div class="test-button">
          <label class="btn1" for="uploads">选择图片</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="uploadImg($event, 1)"
          />
          <button @click="refreshCrop" class="btn1">刷新</button>
          <button @click="changeScale(1)" class="btn1">放大</button>
          <button @click="changeScale(-1)" class="btn1">缩小</button>
          <button @click="rotateLeft" class="btn1">左旋转</button>
          <button @click="rotateRight" class="btn1">右旋转</button>
          <!-- <button @click="finish('base64')" class="btn1">
            preview(base64)
          </button> -->
          <button @click="finish('blob')" class="btn1" style="margin-left: 5vw">
            上传头像
          </button>
        </div>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import { request, request1 ,getpictureHost} from '@/api/net'
import 'vue-cropper/dist/index.css'
import { VueCropper } from 'vue-cropper'
var Console = require('../api/console')
// var logo = 'http://192.168.43.178' + window.localStorage.getItem('avatar')
// if (logo == null || logo == '') {
//   logo = avatar
// }
var province = []
var city = []
var area = []
var street = []

var selectProvince
var selectCity
var selectArea
var token = window.localStorage.getItem('loginToken')
var selectStreet
export default {
  name: 'AppHeaderDropdownAccnt',
  components: { VueCropper },
  data() {
    return {
      picUrl:'',
      show: false,
      modal: false,
      key: 0,
      inputPname: '',
      inputName: '',
      inputPhone: '',
      inputEmail: '',
      inputCompany: '',
      inputPlace: '',
      provinceName: '',
      cityName: '',
      areaName: '',
      streetName: '',
      state: '',
      dealer: '',
      sex: '',
      avater1: '',
      sexlist: [
        { value: 1, text: '男' },
        { value: 2, text: '女' },
      ],
      provSel: [],
      citySel: [],
      areaSel: [],
      streetSel: [],
      previews: {},
      option: {
        img: avatar,
        size: 1,
        full: true,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: true,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 160,
        autoCropHeight: 150,
        centerBox: true,
        high: true,
        max: 99999,
      },
      fixed: true,
      fixedNumber: [1, 1],
      fileName: '',
    }
  },
  created() {
    this.getinfo()
    this.loadProvince()
    this.picUrl=getpictureHost()
    // var logo = 'http://192.168.43.178' + window.localStorage.getItem('avatar')
    // if (logo == 'http://192.168.43.178') {
    //   this.avatar1 = avatar
    // } else this.avatar1 = logo
    var logo = this.picUrl + window.localStorage.getItem('avatar')
    if (logo == this.picUrl) {
      this.avatar1 = avatar
    } else this.avatar1 = logo
  },
  methods: {
    getinfo() {
      var params = {
        url: 'user/getByToken',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }
      params.data = {
        token: token,
      }
      params.reqSuccess = this.Success
      params.reqFailed = this.Failed
      request(params)
    },
    Success(data) {
      console.log('获取成功')
      this.inputPname = data.data.nickName
      this.inputName = data.data.userName
      this.inputPhone = data.data.account
      this.inputEmail = data.data.email
      this.inputCompany = data.data.companyName
      this.inputPlace = data.data.address
      this.provinceName = data.data.provinceName
      this.cityName = data.data.cityName
      this.areaName = data.data.areaName
      this.streetName = data.data.streetName
      this.state = data.data.state
      this.dealer = data.data.dealer
      // this.avatar1 = 'http://192.168.43.178' + data.data.avatar
      this.avatar1 = this.picUrl+ data.data.avatar
      this.key += 1
      if (data.data.sex == 2) {
        this.sex = '女'
      } else {
        this.sex = '男'
      }
    },
    Failed(data) {
      console.log('获取失败')
      alert(data.msg)
    },
    loadProvince() {
      var params = {
        url: '',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }

      params.url = 'location/province'
      params.data = {}
      params.reqSuccess = this.onProvinceLoadSuccess
      params.reqFailed = this.onProvinceLoadFailed
      request(params)
    },
    onProvinceLoadSuccess(data) {
      province = data.data
      selectProvince = province[0]
      for (var i = 0; i < province.length; i++) {
        var prov = province[i]
        var dispName = prov.shortName
        this.provSel.push(dispName)
      }
      Console(selectProvince)
      for (var j = 0; j < province.length; j++) {
        if (this.provSel[j] == this.provinceName) {
          selectProvince = province[j]
        }
      }
      Console(selectProvince)
      this.loadCity(selectProvince.provinceCode)
    },

    onProvinceLoadFailed(error) {
      console.log(error)
    },
    //加载城市
    loadCity(pCode) {
      var params = {
        url: '',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }
      params.url = 'location/city'
      params.data = { provinceCode: pCode }
      params.reqSuccess = this.onCityLoadSuccess
      params.reqFailed = this.onCityLoadFailed
      request(params)
    },

    onCityLoadSuccess(data) {
      this.citySel = []
      city = data.data
      selectCity = city[0]
      for (var i = 0; i < city.length; i++) {
        var prov = city[i]
        var dispName = prov.shortName
        this.citySel.push(dispName)
      }

      for (var j = 0; j < city.length; j++) {
        if (this.citySel[j] === this.cityName) {
          selectCity = city[j]
        }
      }
      this.loadArea(selectCity.cityCode)
    },

    onCityLoadFailed(error) {
      Console(error)
    },
    //加载地区
    loadArea(cCode) {
      var params = {
        url: '',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }

      params.url = 'location/area'
      params.data = { cityCode: cCode }
      params.reqSuccess = this.onAreaLoadSuccess
      params.reqFailed = this.onAreaLoadFailed
      request(params)
    },

    onAreaLoadSuccess(data) {
      area = data.data
      selectArea = area[0]
      this.areaSel = []
      for (var i = 0; i < area.length; i++) {
        var prov = area[i]
        var dispName = prov.shortName
        this.areaSel.push(dispName)
      }
      for (var j = 0; j < area.length; j++) {
        if (this.areaSel[j] === this.areaName) {
          selectArea = area[j]
        }
      }
      if (area.length != 0) {
        this.loadStreet(selectArea.areaCode)
      } else {
        this.streetSel = []
      }
    },

    onAreaLoadFailed(error) {
      Console(error)
    },

    //加载街道
    loadStreet(aCode) {
      var params = {
        url: '',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }

      params.url = 'location/street'
      params.data = { areaCode: aCode }
      params.reqSuccess = this.onLoadStreetSuccess
      params.reqFailed = this.onLoadStreetFailed
      request(params)
    },

    onLoadStreetSuccess(data) {
      street = data.data
      selectStreet = street[0]
      this.streetSel = []
      for (var i = 0; i < street.length; i++) {
        var prov = street[i]
        var dispName = prov.shortName
        this.streetSel.push(dispName)
      }
      for (var j = 0; j < street.length; j++) {
        if (this.streetSel[j] === this.streetName) {
          selectStreet = street[j]
        }
      }
    },
    onLoadStreetFailed(error) {
      Console(error)
    },
    change1(e) {
      selectProvince = province[e.target.selectedIndex]
      this.loadCity(selectProvince.provinceCode)
    },
    change2(e) {
      selectCity = city[e.target.selectedIndex]
      this.loadArea(selectCity.cityCode)
    },
    change3(e) {
      selectArea = area[e.target.selectedIndex]
      this.loadStreet(selectArea.areaCode)
    },
    change4(e) {
      selectStreet = street[e.target.selectedIndex]
    },
    change5(e) {
      Console(e.target.value)
    },
    verify() {
      var sex
      if (this.sex == '男') {
        sex = 1
      } else if (this.sex == '女') {
        sex = 2
      }
      var params = {
        url: 'user/update',
        data: null,
        reqSuccess: null,
        reqFailed: null,
      }

      params.data = {
        userToken: token,
        nickName: this.inputPname,
        state: this.state,
        sex: sex,
        provinceCode: selectProvince.provinceCode,
        cityCode: selectCity.cityCode,
        areaCode: selectArea.areaCode,
        streetCode: selectStreet.streetCode,
        dealer: this.dealer,
        companyName: this.inputCompany,
        address: this.inputPlace,
        userName: this.inputName,
        email: this.inputEmail,
        birthday: '',
        provinceName: selectProvince.shortName,
        cityName: selectCity.shortName,
        areaName: selectArea.shortName,
        streetName: selectStreet.shortName,
      }
      params.reqSuccess = this.reqSuccess1
      params.reqFailed = this.reqFailed1
      request(params)
    },
    reqSuccess1(data) {
      alert(data.msg)
      this.modal = false
      this.getinfo()
    },
    reqFailed1(data) {
      if (data.msg != null) alert(data.msg)
      else alert('保存失败!')
    },
    back() {
      
      this.$router.push('/pages/Login')
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh()
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      // 输出
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          console.log(data)
          let formData = new FormData()
          formData.append('avatar', data)
          formData.append('userToken', token)
          var params = {
            url: 'user/updateAvatar',
            data: null,
            reqSuccess: null,
            reqFailed: null,
          }
          params.data = formData
          params.reqSuccess = this.uploadSuccess
          params.reqFailed = this.uploadFailed
          request1(params)
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          Console(data)
        })
      }
    },
    uploadSuccess() {
      alert('头像上传成功！')
      this.show = false
      this.getinfo()
    },
    uploadFailed(data) {
      alert('头像上传失败！')
      Console(data.msg)
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data
      console.log(data)
    },
    uploadImg(e) {
      //上传图片
      // this.option.img
      var file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
        return false
      }
      this.fileName = file.name
      var reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }

        this.option.img = data
      }
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file)
    },
    imgLoad(msg) {
      console.log(msg)
    },
    cropMoving(data) {
      console.log(data, '截图框当前坐标')
    },
  },
  // setup() {
  //   return {
  //     avatar: logo,
  //   }
  // },
}
</script>
<style>
.test-button {
  display: flex;
  flex-wrap: wrap;
}

.btn1 {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c0ccda;
  color: #1f2d3d;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 20px 10px 0px 0px;
  padding: calc(100vw * 9 / 1920) calc(100vw * 9 / 1920);
  font-size:calc(100vw * 14 / 1920); 
  border-radius: 4px;
  color: #fff;
  background-color: #50bfff;
  border-color: #50bfff;
  transition: all 0.2s ease;
  text-decoration: none;
  user-select: none;
  width:calc(100vw * 100 / 1920);
}
</style>
