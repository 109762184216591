import axios from 'axios'
import qs from 'qs'
// import Console from '../api/console'
var Console = require('../api/console')
var pictureHost = 'https://crmicrobms.com'
// var pictureHost = 'https://iot.crmicro.cn'
// var pictureHost = 'http://192.168.43.178:8081'
// var pictureHost = 'http://f341046e65.goho.co:25455'
// var pictureHost = 'http://192.168.40.24:80'
var host = pictureHost + '/iot/'
// var host = pictureHost + ''
const request = function (params) {
  var url = host + params.url
  var data = params.data
  Console(url)
  var userToke = window.localStorage.getItem('loginToken')
  var header = {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'client-type': 'CR_Web',
    'cq-crmicro': userToke,
  }
  Console(data)
  Console(header)
  axios({
    method: 'post',
    url: url,
    data: qs.stringify(data),
    headers: header,
  })
    .then(function (data) {
      Console(data)
      if (data.data.state == 200) {
        params.reqSuccess(data.data)
      } else if (data.data.state == 202) {
        //账号过期 需要重新登录
        alert('请重新登录')
        // this.$router.push('/login')
      } else params.reqFailed(data.data)
    })
    .catch(function (error) {
      console.log('错误日志:', error)
    })
}
const request1 = function (params) {
  var url = host + params.url
  var data = params.data
  Console(url)
  var userToke = window.localStorage.getItem('loginToken')
  var header = {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'client-type': 'CR_Web',
    'cq-crmicro': userToke,
  }
  Console(data)
  Console(header)
  axios({
    method: 'post',
    url: url,
    data: data,
    headers: header,
  })
    .then(function (data) {
      Console(data)
      if (data.data.state == 200) {
        params.reqSuccess(data.data)
      } else if (data.data.state == 202) {
        //账号过期 需要重新登录
        alert('请重新登录')
        // this.$router.push('/login')
      } else params.reqFailed(data.data)
    })
    .catch(function (error) {
      console.log('错误日志:', error)
    })
}
const getPictureVerifyUrl = function (id) {
  // return host + 'verifyCode/getVerifyPictureCode?deviceId=Web_' + guid()
  return host + 'verifyCode/getVerifyPictureCode?deviceId=' + id
}
function guid() {
  return Number(Math.random().toString().substring(3, 6) + Date.now()).toString(
    36,
  )
}
function getpictureHost() {
  return pictureHost;
}

export { request, guid, getPictureVerifyUrl, request1,getpictureHost }
