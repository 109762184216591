<template>
  <CFooter position="sticky">
    <div>
      <!-- <a href="https://coreui.io" target="_blank">Copyright</a> -->
      Copyright
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}
        <span style="font-weight: bold">系统应用测试用</span>. All rights
        reserved.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank" style="font-weight: bold">Version</span
      >1.0.0
      <!-- <a href="https://coreui.io/vue">CoreUI for Vue</a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
