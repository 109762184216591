import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: '/bms/real',
    name:'real',
    component: () => import('@/views/bms/real.vue'),
  },
  {
    path: '/home',
    name: '首页',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: '首页',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/user',
        name: '用户管理',
        redirect: '/user/userList',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/user/userList',
            name: '用户列表',
            component: () => import('@/views/user/userList.vue'),
          }
        ]
        
      },
      {
        path: '/system',
        name: '系统管理',
        redirect: '/system/privacy',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/system/privacy',
            name: '隐私协议',
            component: () => import('@/views/system/privacy.vue'),
          },
          {
            path: '/system/agreement',
            name: '用户协议',
            component: () => import('@/views/system/agreement.vue'),
          },
          {
            path: '/system/update',
            name: 'APP更新',
            component: () => import('@/views/system/update.vue'),
          },
        ]
        
      },
      {
        path: '/bms',
        name: 'BMS',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/bms/deviceType',
        children: [
          // {
          //   path: '/bms/userList',
          //   name: '用户列表',
          //   component: () => import('@/views/bms/userList.vue'),
          // },
          {
            path: '/bms/deviceType',
            name: '设备类型',
            component: () => import('@/views/bms/deviceType.vue'),
          },
          
          {
            path: '/bms/fault',
            name: '故障管理',
            component: () => import('@/views/bms/fault.vue'),
          },
          {
            path: '/bms/track',
            name: 'track',
            component: () => import('@/views/bms/track.vue'),
          },
          {
            path: '/bms/data',
            name: 'data',
            component: () => import('@/views/bms/data.vue'),
          },
          {
            path: '/bms/history',
            name: 'history',
            component: () => import('@/views/bms/history.vue'),
          },
          {
            path: '/bms/deviceList',
            name: '设备列表',
            component: () => import('@/views/bms/deviceList.vue'),
          },
        ]
      },
      {
        path: '/controller',
        name: '控制器',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/controller/controllerType',
        children: [
          {
            path: '/controller/controllerType',
            name: '控制器类型',
            component: () => import('@/views/controller/controllerType.vue'),
          },
          {
            path: '/controller/controllerList',
            name: '控制器列表',
            component: () => import('@/views/controller/controllerList.vue'),
          },
          {
            path: '/controller/Json',
            name: 'JSON文件',
            component: () => import('@/views/controller/Json.vue'),
          },
          {
            path: '/controller/firmware',
            name: '固件',
            component: () => import('@/views/controller/firmware.vue'),
          },
          {
            path: '/controller/icons',
            name: '图标',
            component: () => import('@/views/controller/icons.vue'),
          },
          {
            path: '/controller/CAN',
            name: 'CAN文件',
            component: () => import('@/views/controller/CAN.vue'),
          },
        ]
      },
    ]
  },
  
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'Forget',
        name: 'Forget',
        component: () => import('@/views/pages/Forget'),
      },
    ],
  },
  {
    path: '/app',
    redirect: '/app/appRegister',
    name: 'app',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'appForget',
        name: 'appForget',
        component: () => import('@/views/app/Forget'),
      },
      {
        path: 'appRegister',
        name: 'appRegister',
        component: () => import('@/views/app/Register'),
      },
    ],
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
